import { useEffect, useState } from "react";

import { AnimatePresence } from "framer-motion";

import * as Styled from "./CaseStudy.styles";

import { CaseStudyIconEllipsis } from "assets/caseStudyIconEllipsis";
import { CaseStudyIconSpiral } from "assets/caseStudyIconSpiral";
import { CaseStudyIconLines } from "assets/caseStudyIconLines";
import { CaseStudyIconVitalis } from "assets/caseStudyIconVitalis";
import { CaseStudyIconAreo } from "assets/caseStudyIconAreo";
import { CaseStudyIconEclipse } from "assets/caseStudyIconEclipse";
import Button from "components/button/Button";
import Typography from "components/typography/Typography";

export interface CaseStudyProps {
  id?: number;
  href?: string;
  imageSrc: string;
  title: string;
  description: string;
  icon?: "ellipsis" | "lines" | "spiral" | 'vitalis' | 'areo' | 'eclipse';
  desktopSlideChange?: (slide: number) => void;
  desktopMaxSlides?: number;
  imageCss?: string;
  imageWrapperCss?: string;
  isMobile?: boolean;
}

export const CaseStudy = ({
  id,
  href,
  imageSrc,
  imageCss,
  imageWrapperCss,
  title,
  description,
  icon,
  desktopSlideChange,
  desktopMaxSlides,
  isMobile,
}: CaseStudyProps) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleNextClick = () => {
    desktopMaxSlides &&
      setCurrentSlide((prev) =>
        prev === desktopMaxSlides ? (prev = 1) : prev + 1
      );
  };
  const handlePrevClick = () => {
    desktopMaxSlides &&
      setCurrentSlide((prev) =>
        prev === 1 ? (prev = desktopMaxSlides) : prev - 1
      );
  };

  useEffect(() => {
    desktopSlideChange && desktopSlideChange(currentSlide - 1);
  }, [currentSlide, desktopSlideChange]);

  return (
    <Styled.Wrapper
      onClick={
        isMobile
          ? () => {
            window.open(href, "_blank");
          }
          : undefined
      }
    >
      <AnimatePresence>
        <Styled.CaseStudyBody>
          <Styled.ImageWrapper
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            key={`counter_${id}`}
            $css={imageWrapperCss}
          >
            <Styled.Image
              src={imageSrc}
              $css={imageCss}
              alt="Case Study lead image"
            />
          </Styled.ImageWrapper>
          <Styled.InfoWrapper>
            <Styled.InfoContent>
              <Styled.InfoBody>
                <Styled.IconWrapper
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{ duration: 0.3 }}
                  key={`icon_${id}`}
                >
                  {icon === "ellipsis" ? (
                    <CaseStudyIconEllipsis />
                  ) : icon === "lines" ? (
                    <CaseStudyIconLines />
                  ) : icon === "spiral" ? (
                    <CaseStudyIconSpiral />
                  ) : icon === 'vitalis' ? (
                    <CaseStudyIconVitalis />
                  ) : icon === 'areo' ? (
                    <CaseStudyIconAreo />
                  ) : icon === 'eclipse' && (
                    <CaseStudyIconEclipse />
                  )}
                </Styled.IconWrapper>
                <Styled.InfoTitle
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.15 }}
                  key={`title_${id}`}
                >
                  {title}
                </Styled.InfoTitle>
                <Styled.InfoDescription
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                  key={`description_${id}`}
                >
                  {description}
                </Styled.InfoDescription>
                {href && (<Styled.DesktopButtonWrapper
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.45 }}
                  key={`button_${id}`}
                >
                  <Button variant="cta" cta="Discover" href={href} ariaLabel="Discover" />
                </Styled.DesktopButtonWrapper>)}
              </Styled.InfoBody>
              <Styled.SwiperMenu>
                <Styled.SwiperMenuBody>
                  <Styled.SwiperMenuCounterWrapper>
                    <Styled.Counter>
                      <Typography variant="sm">
                        {String(currentSlide).padStart(2, "0")}
                      </Typography>
                    </Styled.Counter>
                    <Styled.Separator>
                      <Typography variant="sm" color="hitPink">
                        /
                      </Typography>
                    </Styled.Separator>
                    <Styled.Counter>
                      <Typography variant="sm">
                        {String(desktopMaxSlides).padStart(2, "0")}
                      </Typography>
                    </Styled.Counter>
                  </Styled.SwiperMenuCounterWrapper>
                  <Styled.SwiperMenuButtonsWrapper>
                    <Button variant="arrow" onClick={handlePrevClick} />
                    <Button
                      variant="arrow"
                      direction="right"
                      onClick={handleNextClick}
                    />
                  </Styled.SwiperMenuButtonsWrapper>
                </Styled.SwiperMenuBody>
              </Styled.SwiperMenu>
            </Styled.InfoContent>
          </Styled.InfoWrapper>
        </Styled.CaseStudyBody>
      </AnimatePresence>
    </Styled.Wrapper>
  );
};

export default CaseStudy;
