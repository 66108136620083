import { motion } from "framer-motion";
import type { HighlightedProject } from "../HighlightedProjects.mock";
import CaseStudyIconEllipsis from "assets/caseStudyIconEllipsis";
import CaseStudyIconSpiral from "assets/caseStudyIconSpiral";
import CaseStudyIconEclipse from "assets/caseStudyIconEclipse";
import * as Styled from "./ProjectDescription.styles";

type ProjectDescriptionProps = {
  hasAlreadySwitchedSlide: boolean;
  project: HighlightedProject;
  children?: React.ReactNode;
};

const ProjectDescription = ({
  hasAlreadySwitchedSlide,
  project,
  children,
}: ProjectDescriptionProps) => {
  return (
    <Styled.ProjectDescription>
      <div className="ProjectDescription-wrapper">
        <motion.div
          className="ProjectDescription-icon"
          initial={{ opacity: 0, y: -20 }}
          animate={hasAlreadySwitchedSlide ? { opacity: 1, y: 0 } : undefined}
          whileInView={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          key={`icon_${project.id}`}
        >
          {project.icon === "ellipsis" ? (
            <CaseStudyIconEllipsis width={45} height={45} />
          ) : project.icon === "spiral" ? (
            <CaseStudyIconSpiral width={45} height={45} />
          ) : project.icon === 'eclipse' ? (
            <CaseStudyIconEclipse width={45} height={45} />
          ) : null}
        </motion.div>
        <motion.p
          className="ProjectDescription-heading"
          initial={{ opacity: 0 }}
          animate={hasAlreadySwitchedSlide ? { opacity: 1 } : undefined}
          whileInView={{ opacity: 1, transition: { delay: 0.1 } }}
          transition={{ duration: 0.3, delay: 0.15 }}
          key={`title_${project.id}`}
        >
          {project.title}
        </motion.p>
        <motion.p
          className="ProjectDescription-description"
          initial={{ opacity: 0 }}
          animate={hasAlreadySwitchedSlide ? { opacity: 1 } : undefined}
          whileInView={{ opacity: 1, transition: { delay: 0.2 } }}
          transition={{ duration: 0.3, delay: 0.3 }}
          key={`description_${project.id}`}
        >
          {project.description}
        </motion.p>
        {children}
      </div>
    </Styled.ProjectDescription>
  );
};

export default ProjectDescription;
