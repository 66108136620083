import { SVGProps } from "react";

export const CaseStudyIconEclipse = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="67" height="66" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.283203 33.1025C0.283203 51.254 14.9979 65.9688 33.1494 65.9688C51.3009 65.9688 66.0156 51.254 66.0156 33.1025V33.1001C66.0143 14.9497 51.3001 0.236328 33.1494 0.236328C14.9979 0.236328 0.283203 14.951 0.283203 33.1025ZM33.1494 62.7854C41.56 62.7854 49.1535 59.2874 54.5546 53.6668C50.8616 55.9545 46.5065 57.2751 41.8431 57.2751C28.4929 57.2751 17.6705 46.4527 17.6705 33.1025C17.6705 19.7524 28.4929 8.92995 41.8431 8.92995C46.5065 8.92995 50.8615 10.2505 54.5545 12.5382C49.1535 6.91768 41.5599 3.41971 33.1494 3.41971C19.3069 3.41971 7.67749 12.8952 4.39351 25.7138C5.72419 24.8866 7.29473 24.4089 8.97685 24.4089C13.7782 24.4089 17.6705 28.3012 17.6705 33.1025C17.6705 37.9039 13.7782 41.7962 8.97685 41.7962C7.29473 41.7962 5.72419 41.3184 4.39351 40.4912C7.67748 53.3099 19.3069 62.7854 33.1494 62.7854ZM3.46659 33.1025C3.46659 36.1458 5.93361 38.6128 8.97685 38.6128C12.0201 38.6128 14.4871 36.1458 14.4871 33.1025C14.4871 30.0593 12.0201 27.5923 8.97685 27.5923C5.93361 27.5923 3.46659 30.0593 3.46659 33.1025ZM41.8431 12.1133C53.4351 12.1133 62.8322 21.5105 62.8322 33.1025C62.8322 44.6945 53.4351 54.0917 41.8431 54.0917C30.2511 54.0917 20.8539 44.6945 20.8539 33.1025C20.8539 21.5105 30.2511 12.1133 41.8431 12.1133Z" fill="white" />
    </svg>

  );
};

export default CaseStudyIconEclipse;
