import { SVGProps } from "react";

export const CaseStudyIconVitalis = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="251" height="64" viewBox="0 0 251 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M118.225 12.531H126.96L111.339 51.5547H105.46L89.7835 12.531H98.5176L108.428 38.5655L118.225 12.531Z" fill="white" />
      <path d="M125.576 51.5547V12.531H133.471V51.5547H125.576Z" fill="white" />
      <path d="M144.21 51.5547V19.7535H132.396V12.531H163.973V19.7535H152.16V51.5547H144.21Z" fill="white" />
      <path d="M183.512 51.5547L180.769 44.2203H165.764L163.02 51.5547H154.398L170.355 12.531H176.233L192.078 51.5547H183.512ZM168.507 36.9419H178.081L173.322 24.2326L168.507 36.9419Z" fill="white" />
      <path d="M190.685 51.5547V12.531H198.579V44.2763H214.927V51.5547H190.685Z" fill="white" />
      <path d="M213.9 51.5547V12.531H221.794V51.5547H213.9Z" fill="white" />
      <path d="M236.62 52.3386C228.67 52.3386 222.455 47.5796 221.279 40.3011L229.118 38.3416C229.566 42.9326 232.533 45.3401 236.732 45.3401C240.147 45.3401 242.555 43.6604 242.555 40.5251C242.555 38.2856 240.651 36.7739 235.22 34.5904C226.99 31.2871 224.023 28.2637 224.023 22.4409C224.023 16.0583 229.006 11.5792 236.452 11.5792C241.827 11.5792 246.082 14.0427 248.546 18.9137L241.995 23.0008C240.763 20.0894 238.636 18.5778 236.228 18.5778C233.821 18.5778 231.917 20.1454 231.917 22.273C231.917 24.5125 233.597 25.9122 239.364 28.1517C247.706 31.399 250.449 34.2544 250.449 39.7972C250.449 47.2997 244.57 52.3386 236.62 52.3386Z" fill="white" />
      <path d="M44.8409 61.9348C59.0503 59.1061 69.7598 46.5667 69.7598 31.5259C69.7598 14.4015 55.8778 0.519531 38.7534 0.519531C28.1166 0.519531 18.7308 5.87564 13.1458 14.0379C15.1142 13.6461 17.1497 13.4406 19.2333 13.4406C36.3576 13.4406 50.2396 27.3226 50.2396 44.4469C50.2396 50.9344 48.2472 56.9566 44.8409 61.9348Z" fill="#569DD8" />
      <path d="M38.1847 44.4476C38.1847 54.9139 29.7002 63.3984 19.2339 63.3984C8.76774 63.3984 0.283203 54.9139 0.283203 44.4476C0.283203 33.9814 8.76774 25.4969 19.2339 25.4969C29.7002 25.4969 38.1847 33.9814 38.1847 44.4476Z" fill="#569DD8" />
    </svg>
  );
};

export default CaseStudyIconVitalis;
