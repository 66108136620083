import { SVGProps } from "react";

export const CaseStudyIconAreo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="250" height="28" viewBox="0 0 250 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.283691 10.2999C0.283691 9.00581 0.537051 7.78634 1.04377 6.64153C1.55049 5.49672 2.23456 4.50123 3.09599 3.65506C3.95741 2.80889 4.95818 2.13694 6.0983 1.6392C7.26376 1.14145 8.50522 0.892578 9.82269 0.892578H49.3468C50.6643 0.892578 51.9058 1.14145 53.0712 1.6392C54.2367 2.13694 55.2501 2.80889 56.1115 3.65506C56.973 4.50123 57.6444 5.49672 58.1258 6.64153C58.6325 7.78634 58.8858 9.00581 58.8858 10.2999V27.0242H53.2232V19.2967L14.7632 27.0242H0.283691V10.2999ZM5.98429 10.2999V21.4245H14.1931L53.2232 13.5851V10.2999C53.2232 9.77732 53.1219 9.29202 52.9192 8.84405C52.7165 8.37119 52.4378 7.96055 52.0831 7.61213C51.7284 7.26371 51.3104 6.98995 50.829 6.79085C50.3729 6.59175 49.8789 6.4922 49.3468 6.4922H9.82269C9.29063 6.4922 8.78392 6.59175 8.30253 6.79085C7.84648 6.98995 7.44111 7.26371 7.0864 7.61213C6.75704 7.96055 6.49101 8.37119 6.28832 8.84405C6.08563 9.29202 5.98429 9.77732 5.98429 10.2999Z" fill="white" />
      <path d="M64.6099 0.892578H114.091C115.611 0.892578 116.954 1.19122 118.119 1.78852C119.31 2.38581 120.286 3.15732 121.046 4.10303C121.831 5.04874 122.389 6.11889 122.718 7.31348C123.073 8.50807 123.174 9.7151 123.022 10.9346C122.895 12.154 122.49 13.3237 121.806 14.4437C121.147 15.5636 120.197 16.5093 118.956 17.2808C120.273 18.5501 121.299 20.006 122.034 21.6485C122.769 23.2662 123.136 25.0581 123.136 27.0242H117.435C117.435 25.8047 117.195 24.6848 116.713 23.6644C116.257 22.644 115.599 21.773 114.737 21.0512C113.901 20.3046 112.9 19.7322 111.735 19.334C110.569 18.9109 109.303 18.6994 107.934 18.6994H76.5811V13.0998H114.091C114.826 13.0998 115.434 12.938 115.915 12.6145C116.422 12.2909 116.789 11.8803 117.017 11.3825C117.271 10.8848 117.397 10.3622 117.397 9.81465C117.397 9.24224 117.271 8.70717 117.017 8.20942C116.789 7.71168 116.422 7.30104 115.915 6.9775C115.434 6.65397 114.826 6.4922 114.091 6.4922H70.3105V27.0242H64.6099V0.892578Z" fill="white" />
      <path d="M127.838 27.0242V11.1586H171.125V16.7582H133.539V21.4245H187.505V27.0242H127.838ZM127.838 6.4922V0.892578H187.505V6.4922H127.838Z" fill="white" />
      <path d="M190.926 17.6168V10.2626C190.926 8.96848 191.179 7.76145 191.686 6.64153C192.192 5.49672 192.876 4.50123 193.738 3.65506C194.599 2.80889 195.6 2.13694 196.74 1.6392C197.906 1.14145 199.147 0.892578 200.465 0.892578H239.875C241.192 0.892578 242.421 1.14145 243.561 1.6392C244.727 2.11205 245.74 2.77156 246.601 3.61773C247.488 4.46389 248.185 5.45938 248.692 6.60419C249.198 7.72412 249.452 8.93115 249.452 10.2253L249.49 17.5795C249.49 18.8736 249.236 20.1055 248.73 21.2752C248.223 22.42 247.539 23.4155 246.677 24.2617C245.816 25.1078 244.803 25.7798 243.637 26.2775C242.472 26.7753 241.23 27.0242 239.913 27.0242H200.465C199.147 27.0242 197.906 26.7753 196.74 26.2775C195.6 25.7798 194.599 25.1078 193.738 24.2617C192.876 23.4155 192.192 22.42 191.686 21.2752C191.179 20.1304 190.926 18.9109 190.926 17.6168ZM200.465 21.4245H239.913C240.445 21.4245 240.939 21.325 241.395 21.1259C241.876 20.9268 242.294 20.653 242.649 20.3046C243.004 19.9562 243.282 19.558 243.485 19.11C243.688 18.6372 243.789 18.1394 243.789 17.6168L243.751 10.2626C243.751 9.73999 243.65 9.25468 243.447 8.80671C243.244 8.33386 242.966 7.93566 242.611 7.61213C242.256 7.26371 241.838 6.98995 241.357 6.79085C240.901 6.59175 240.407 6.4922 239.875 6.4922H200.465C199.933 6.4922 199.426 6.59175 198.944 6.79085C198.488 6.98995 198.083 7.26371 197.728 7.61213C197.399 7.93566 197.133 8.33386 196.93 8.80671C196.728 9.25468 196.626 9.73999 196.626 10.2626V17.6541C196.626 18.1768 196.728 18.6745 196.93 19.1474C197.133 19.5953 197.399 19.9935 197.728 20.3419C198.083 20.6655 198.488 20.9268 198.944 21.1259C199.426 21.325 199.933 21.4245 200.465 21.4245Z" fill="white" />
    </svg>
  );
};

export default CaseStudyIconAreo;
