import { CaseStudyProps } from "components/case-study/CaseStudy";

import caseStudyMock from "assets/images/case-study-mock.jpg";
import caseStudyMock1 from "assets/images/case-study-mock-1.jpeg";
import caseStudyMock2 from "assets/images/case-study-mock-2.png";
import vitalis from 'assets/images/vitalis.png';
import areo from 'assets/images/areo.png';
import eclipse from 'assets/images/eclipse.png';

export const MobileCaseStudies: CaseStudyProps[] = [
  {
    id: 0,
    imageSrc: caseStudyMock,
    title: "Perfection & creativity in every step",
    description:
      "Unlock new realms of creativity with our bespoke solutions, designed to bring your boldest ideas to life.",
    icon: "ellipsis",
    href: "https://www.behance.net/gallery/200863177/ELIPSE-DYNAMICS",
  },
  {
    id: 1,
    imageSrc: caseStudyMock1,
    title: "Enter the darkness",
    description:
      "Elevate your vision with our groundbreaking designs that transform imagination into reality, one unique creation at a time.",
    icon: "spiral",
    href: "https://www.behance.net/gallery/201253749/ULTRABOT-Design-Website",
  },
  {
    id: 2,
    imageSrc: caseStudyMock2,
    title: "Borders no longer exist",
    description:
      "Empowering you to explore the full potential of your ideas through custom, innovative solutions tailored to your unique vision.",
    icon: "lines",
    imageWrapperCss:
      "background-image: url('/sun.svg'); background-size: 100%;",
    imageCss:
      "max-width: 300px; height: auto; margin: auto; transform: translateY(25px);",
    href: "https://www.instagram.com/p/C7wSa5st0Lf",
  },
  {
    id: 3,
    imageSrc: vitalis,
    title: "Vitalis - Revolution in Medicine",
    description:
      "An innovative startup that is redefining modern medicine by combining the latest scientific advances with advanced technologies.",
    icon: "vitalis",
    href: "https://www.behance.net/gallery/213435073/Vitalis-Revolution-in-Medicine",
  },
  {
    id: 4,
    imageSrc: areo,
    title: "Unlock the Future of Aerial Technology!",
    description:
      "We specialize in the design, development, and deployment of cutting-edge Unmanned Aerial Vehicles (UAVs) that revolutionize industries worldwide.",
    icon: "areo",
    href: "https://www.behance.net/gallery/212429745/AREO-Drone",
  },
  {
    id: 5,
    imageSrc: eclipse,
    title: "A scent that lingers in memory",
    description:
      "Orion is a unique fragrance that blends elegance and strength of a man. Inspired by the celestial constellation, it is the perfect choice for exceptional moments",
    icon: "eclipse",
    href: "https://www.behance.net/gallery/213573871/ORION",
  },
];

export const DesktopCaseStudies: CaseStudyProps[] = [
  {
    id: 0,
    imageSrc: caseStudyMock,
    title: "Perfection & creativity in every step",
    description:
      "Unlock new realms of creativity with our bespoke solutions, designed to bring your boldest ideas to life.",
    icon: "ellipsis",
    href: "https://www.behance.net/gallery/200863177/ELIPSE-DYNAMICS",
  },
  {
    id: 1,
    imageSrc: caseStudyMock1,
    title: "Enter the darkness",
    description:
      "Elevate your vision with our groundbreaking designs that transform imagination into reality, one unique creation at a time.",
    icon: "spiral",
    href: "https://www.behance.net/gallery/201253749/ULTRABOT-Design-Website",
  },
  {
    id: 2,
    imageSrc: caseStudyMock2,
    title: "Borders no longer exist",
    description:
      "Empowering you to explore the full potential of your ideas through custom, innovative solutions tailored to your unique vision.",
    icon: "lines",
    imageCss:
      "max-width: 450px; height: auto; margin: auto; transform: translateY(25px);",
    imageWrapperCss:
      "background-image: url('/sun.svg'); background-size: 100%; background-position: center; background-repeat: no-repeat;",
    href: "https://www.instagram.com/p/C7wSa5st0Lf",
  },
  {
    id: 3,
    imageSrc: vitalis,
    title: "Vitalis - Revolution in Medicine",
    description:
      "An innovative startup that is redefining modern medicine by combining the latest scientific advances with advanced technologies.",
    icon: "vitalis",
    href: "https://www.behance.net/gallery/213435073/Vitalis-Revolution-in-Medicine",
  },
  {
    id: 4,
    imageSrc: areo,
    title: "Unlock the Future of Aerial Technology!",
    description:
      "We specialize in the design, development, and deployment of cutting-edge Unmanned Aerial Vehicles (UAVs) that revolutionize industries worldwide.",
    icon: "areo",
    href: "https://www.behance.net/gallery/212429745/AREO-Drone",
  },
  {
    id: 5,
    imageSrc: eclipse,
    title: "A scent that lingers in memory",
    description:
      "Orion is a unique fragrance that blends elegance and strength of a man. Inspired by the celestial constellation, it is the perfect choice for exceptional moments",
    icon: "eclipse",
    href: "https://www.behance.net/gallery/213573871/ORION",
  },
];
